.fc-scroller {
  overflow-y: "hidden"; /* Allow vertical scrolling */
  scrollbar-width: none; /* For Firefox */
}

.fc-scroller::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.fc {
  overflow: hidden; /* Prevent overflow issues */
}
