@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
}

.login {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  height: 100% !important;
  width: 100% !important;
  height: 100vh !important;
  width: 100vw !important;
}

.app {
  display: flex;
  position: relative;
}

.quick-filter {
  border: 1px solid black;
  display: none !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fc-scroller .fc-scroller-liquid-absolute {
  overflow: "hidden scroll !important";
  scrollbar-width: "thin !important";
}
